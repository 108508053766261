import React from 'react';
import './App.css';
import ShinyButton from './ShinyButton';
import { FaMobileAlt, FaLaptopCode, FaPalette, FaBullhorn, FaRobot, FaInstagram, FaLinkedin, FaEnvelope } from 'react-icons/fa';

function App() {
  const handleMagicClick = () => {
    window.location.href = 'mailto:adam@aisupertutor.org?subject=Collaboration%20Opportunities';
  };

  const projectData = [
    {
      image: "/bc.png",
      title: "Supertutor",
      description: "Built web application with AI tutoring, custom meme generator, multiplayer games, and more",
      url: "https://supertutorai.co"
    },
    {
      image: "/b.png",
      title: "Big Signal",
      description: "Built iOS personal safety app summoning help from your designated contacts simultaneously with 1 click",
      url: "https://apps.apple.com/kg/app/big-signal-personal-safety/id6472224823"
    },
    {
      image: "/h.png",
      title: "Happy Thoughts",
      description: "Built iOS positive affirmations app sending you daily happy thoughts",
      url: "https://apps.apple.com/us/app/happy-thoughts/id6476699027"
    },
    {
      image: "/t.png",
      title: "Tesla",
      description: "Worked as top performing autopilot data specialist averaging 300% higher efficiency and 30% higher accuracy than median",
      url: "https://www.tesla.com/autopilot"
    },
    {
      image: "/n.png",
      title: "Northwell Health",
      description: "Designed bespoke interactive 'art as a form of healing' installations for Monter Cancer Center",
      url: "https://cancer.northwell.edu/cancer-care/locations/center-for-advanced-medicine/rj-zuckerberg-cancer-center"
    },
    {
      image: "/primary.png",
      title: "Primary VC",
      description: "Created designs and marketing for Primary NYC Summit with over 2000 top VCs & Founders",
      url: "https://www.primary.vc/"
    }
  ];

  return (
    <div className="App">
      <header className="header">
        <h1 className="studio-name">Adam Jonah Studio</h1>
      </header>
      <main>
        <section className="section sky">
          <div className="clouds">
            <div className="cloud" aria-hidden="true"></div>
            <div className="cloud" aria-hidden="true"></div>
            <div className="cloud" aria-hidden="true"></div>
          </div>
          <h2 className="title">
            Bringing Creative <br />
            Technology <br /> to Life
          </h2>
          <ShinyButton
            text="Let's Collaborate"
            onClick={handleMagicClick}
            className="magic-button"
          />
        </section>

        <section className="section card-section">
          <h2 className="card-section-title">Portfolio of Positive Impact</h2>
          <div className="card-container">
            {projectData.map((project, index) => (
              <a href={project.url} target="_blank" rel="noopener noreferrer" className="card-link" key={index}>
                <article className="card">
                  <img src={project.image} alt={`${project.title} project`} className="card-image" />
                  <h3 className="card-title">{project.title}</h3>
                  <p className="card-description">{project.description}</p>
                </article>
              </a>
            ))}
          </div>
        </section>

        <section className="section expertise-section">
          <h2 className="expertise-title">Specialties</h2>
          <div className="expertise-container">
            <div className="expertise-item">
              <FaMobileAlt className="expertise-icon" aria-hidden="true" />
              <span className="expertise-name">iOS Development</span>
            </div>
            <div className="expertise-item">
              <FaLaptopCode className="expertise-icon" aria-hidden="true" />
              <span className="expertise-name">Web App Development</span>
            </div>
            <div className="expertise-item">
              <FaPalette className="expertise-icon" aria-hidden="true" />
              <span className="expertise-name">Brand Design</span>
            </div>
            <div className="expertise-item">
              <FaBullhorn className="expertise-icon" aria-hidden="true" />
              <span className="expertise-name">Marketing Strategy</span>
            </div>
            <div className="expertise-item">
              <FaRobot className="expertise-icon" aria-hidden="true" />
              <span className="expertise-name">AI Applications</span>
            </div>
          </div>
        </section>

        <section className="section connect-section">
          <h2 className="connect-title">Let's Connect</h2>
          <div className="social-container">
            <a href="https://www.instagram.com/adamjonah_" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram className="social-icon" />
            </a>
            <a href="https://www.linkedin.com/in/adamjonah/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <FaLinkedin className="social-icon" />
            </a>
            <a href="mailto:adam@aisupertutor.org" aria-label="Email">
              <FaEnvelope className="social-icon" />
            </a>
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; {new Date().getFullYear()} Adam Jonah Studio. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;