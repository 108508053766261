"use client";
import React from 'react';
import { motion } from "framer-motion";

const ShinyButton = ({ text = "shiny-button", className, onClick }) => {
  return (
    <motion.button
      className={`shiny-button ${className}`}
      onClick={onClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <span className="button-text">{text}</span>
      <motion.span 
        className="shine-effect"
        animate={{
          x: ["100%", "-100%"],
        }}
        transition={{
          repeat: Infinity,
          repeatType: "loop",
          duration: 1.5,
          ease: "linear",
        }}
      />
    </motion.button>
  );
};

export default ShinyButton;